.d-flex {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-one {
  flex: 1;
}
