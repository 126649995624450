

/*-----------New Variables-------------*/
$header-height: 6.4rem;
$max-width: 145rem;
$border-style: .2rem solid var(--ion-color-light);

/*----------Font Sizes---------------*/
$font-size-sm: 1.2rem;
$font-size-16: 1.6rem;
$font-size-20: 2.0rem;
$font-size-m: 2.4rem;
$font-size-lg: 3.6rem;

/*-----------Colors-------------*/
$white: #FFFFFF;
$light-gray: #FAFBFD;
$button-gray: #F2F2F2;
$color-tertiary: #CED9FE;
$dark-gray: #D7DBE7;
$very-dark-gray: #4d4d4d;

$disabled-gray: #F3F5FD;
$disabled-border: #E6E9F2;

$sidebar-color: #f6f8ff;

/*--------------break points-------------------*/
//get width / 16px for correct em
$bp-xl: 75em; // 1200px desktop
$bp-lg: 62em; // 992px tablet landscape
$bp-md: 48em; // 768px tablet portrait
$bp-md-mobile: 47.9375em; // 767px start of mobile
$bp-sm: 36em; // 576px mobile
$bp-xs: 0em; // 0px

/*------------Animations---------------*/
@mixin animateIn {
  animation-name: animateIn;
  animation-duration: 200ms;
  animation-delay: var(--animation-order);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: scale(0.6) translateY(-.8rem);
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin dropIn {
  animation-name: dropIn;
  animation-duration: 200ms;
  animation-delay: var(--animation-order);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes dropIn {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin bounce {
  animation-name: bounce;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  @keyframes bounce {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

@mixin fadeIn {
  animation-name: fadeIn;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
}

@mixin fade {
  animation: fade 0.8s ease;
    @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin slide {
  animation: slide 0.5s linear forwards;
  @keyframes slide {
    0% {
      width: 0;
    }
    50% {
      width: 100%;
    }
    100% {
      left: 0%;
      width: 0%;
    }
  }
}

@mixin flip {
  animation: flip 0.3s linear;
  @keyframes flip {
    0% {
      transform: rotateY(-90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
}

@mixin shake {
  animations: shake 0.3s 0.3s linear;
  @keyframes shake {
    0% {
      transform: translateX(-3px);
    };
    25% {
      transform: translateX(3px);
    };
    50% {
      transform: translateX(-3px);
    };
    75% {
      transform: translateX(3px);
    };
    100% {
      transform: translateX(-3px);
    }
  }
}

@mixin shake2 {
  animation: shake2 0.3s 0.3s linear;
  @keyframes shake2 {
    0% {
      transform: translate(-3px,-50%);
    };
    25% {
      transform: translate(3px,-50%);
    };
    50% {
      transform: translate(-3px,-50%);
    };
    75% {
      transform: translate(3px,-50%);
    };
    100% {
      transform: translate(-3px,-50%);
    }
  }
}

@mixin buttonIn {
  animation: buttonIn .5s ease-out;
  @keyframes buttonIn {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(.9);
    }

    60% {
      transform: scale(.9);
    }

    100% {
      transform: scale(1);
    }
  }
}

@mixin rotate($params) {
  -webkit-transform: rotate($params);
     -moz-transform: rotate($params);
   -khtml-transform: rotate($params);
       -o-transform: rotate($params);
          transform: rotate($params);
}

@mixin opacity($opacity) {
  -webkit-opacity:$opacity;
     -moz-opacity:$opacity;
   -khtml-opacity:$opacity;
       -o-opacity:$opacity;
          opacity:$opacity;
}

@mixin transition($params) {
  -webkit-transition: $params;
     -moz-transition: $params;
   -khtml-transition: $params;
       -o-transition: $params;
          transition: $params;
}
@mixin warning {
  animation-name: warning;
  animation-duration: 400ms;
  animation-delay: var(--animation-order);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes warning {
    0% {
      background: initial;
    }

    50% {
      background: rgba(var(--ion-color-danger-rgb), 20%);
    }

    100% {
      background: initial;
    }
  }
}

@mixin bp-xl {
    @media only screen and (max-width: #{$bp-xl}) {
        @content;
    }
}

@mixin bp-lg {
    @media only screen and (max-width: #{$bp-lg}) {
        @content;
    }
}

@mixin bp-md {
  @media only screen and (max-width: #{$bp-md}) {
      @content;
  }
}

@mixin bp-md-mobile {
  @media only screen and (max-width: #{$bp-md-mobile}) {
    @content;
  }
}

@mixin bp-sm {
    @media only screen and (max-width: #{$bp-sm}) {
        @content;
    }
}

@mixin bp-xs {
    @media only screen and (max-width: #{$bp-xs}) {
        @content;
    }
}

/* Mixins */

@mixin page-layout {
  max-width: $max-width;
  margin: auto;
  padding: 0 5rem;
}

@mixin container-corners {
  border-radius: 25px;
  background: $white;
  padding: 1.6rem;
}
