@import './scss-variables.scss';

.app-button {
  border: none;
  background: var(--ion-color-primary);
  cursor: pointer;
  border-radius: 7px;
  font-size: 1.8rem;
  padding: .8rem;
  color: white;
  border: .1rem solid var(--ion-color-primary);
  &:focus {
    outline: none;
  }
  &:hover {
    background: $white;
    color: var(--ion-color-primary);
    border: .1rem solid var(--ion-color-primary);
  }

  &.gray {
    background: $button-gray;
    color: var(--ion-color-dark);
    border: .1rem solid $button-gray;
    &:hover {
      background: $white;
      color: var(--ion-color-primary);
      border: .1rem solid var(--ion-color-primary);
    }
  }
  &.dark-gray {
    background: $very-dark-gray;
    color: var(--ion-color-light);
    border: .1rem solid $button-gray;
    &:hover {
      background: $white;
      color: $very-dark-gray;
      border: .1rem solid $very-dark-gray;
    }
  }
  &.yellow {
    background: var(--ion-color-warning);
    color: var(--ion-color-dark);
    border: .1rem solid $button-gray;
    &:hover {
      background: $white;
      color: var(--ion-color-warning);
      border: .1rem solid var(--ion-color-warning);
    }
  }
  &:disabled, &[disabled], &.disabled {
    background: $disabled-gray;
    color: $disabled-border;
    border: .1rem solid $disabled-border;
    &:hover {
      background: $disabled-gray;
      color: $disabled-border;
      border: .1rem solid $disabled-border;
    }
  }

  &.lg-padding {
    padding: 1rem 3rem;
  }
}

.reverse-button {
  border: none;
  background: $white;
  cursor: pointer;
  border-radius: 1rem;
  font-size: 1.8rem;
  padding: .8rem;
  color: var(--ion-color-primary);
  border: .1rem solid transparent;
  &:focus {
    outline: none;
  }
  &:hover {
    background: $white;
    color: var(--ion-color-primary);
    border: .1rem solid var(--ion-color-primary);
  }
}

//----------------LINK------------------//@at-root
.app-link {
  text-decoration: none;
}
