@import '../scss/scss-variables.scss';

.shake-button {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: .1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes shakeMe {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.gradient-border {
  --borderWidth: .5rem;
  position: relative;
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, var(--ion-color-primary), #76e3fc, var(--ion-color-primary), #76e3fc, var(--ion-color-primary), #76e3fc, var(--ion-color-primary), #76e3fc);
  border-radius: 25px;
  z-index: -1;
  -webkit-animation: animatedgradient 2s ease-out alternate infinite;
          animation: animatedgradient 2s ease-out alternate infinite;
  background-size: 300% 300%;
  box-shadow: 0px 0px 17px 0px #7583b3;
}

@-webkit-keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
