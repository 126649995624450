/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');
@import './assets/scss/check-box.scss';
@import './assets/scss/flex-styles.scss';
@import './assets/scss/button-styles.scss';
@import './assets/scss/misc.scss';
@import './assets/scss/input.scss';
@import './assets/scss/icon.scss';
@import './assets/scss/material.scss';
@import './assets/scss/scrollbar.scss';
@import './assets/scss/animations.scss';
@import './assets/scss/color-classes.scss';


//icons and fonts
@import './assets/icons/style.css';

$font-woff: './assets/icons/fonts/icomoon.woff?8a9ays';
$font-svg: './assets/icons/fonts/icomoon.svg';

@font-face {
  font-family: 'icomoon';
  src: url($font-woff) format('woff'), url($font-svg) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

//------------------------------------------
*{
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  scrollbar-color: var(--inputbox-color) var(--content-color);
  scrollbar-width: thin !important;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
}

html {
  font-size: 62.5%;
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 100rem #ffffff inset !important;
  -webkit-text-fill-color: black;

  -webkit-box-shadow: 0 0 0 100rem rgba(0, 0, 0, 0);
  transition: background-color 5000s ease-in-out 0s;
}

html, body {
  height: 100%;
}

body {
  font-size: 1.6rem;
}

.main {
  position: relative;
  // overflow: auto;
}

router-outlet {
  height: 100%;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
