@import '../../assets/scss/scss-variables.scss';

/*--- Classes ------------*/
  /*------- Colors ------*/
  .success {
    color: var(--ion-color-success);
  }

  .warning {
    color: var(--ion-color-danger);
  }

  .warning-yellow {
    color: var(--ion-color-warning);
  }

  .primary {
    color: var(--ion-color-primary);
  }

  .dark {
    color: var(--ion-color-dark);
  }

  .medium {
    color: var(--ion-color-medium);
  }

  .light {
    color: var(--ion-color-light);
  }

  .text-color-secondary {
    color: var(--ion-color-secondary);
  }

  .text-color-light-mode {
    color: var(--ion-text-color);
  }

  .neutral {
    color: var(--ion-color-medium);
  }

  .font-size-sm {
    font-size: $font-size-sm;
  }

  .font-size-20 {
    font-size: $font-size-20;
  }

  .font-size-m {
    font-size: $font-size-m;
  }

  .font-size-lg {
    font-size: $font-size-lg;
  }
    /*------- Sizing/Layout ------*/

  .main-title {
    font-size: $font-size-lg;
    color: var(--ion-color-dark);
    margin-bottom: .8rem;
    font-weight: 400;
  }

  .sub-title {
    font-size: $font-size-m;
    color: var(--ion-color-dark);
  }

  .layout-sm {
    width: 30rem;
  }

  .layout-m {
    width: 50rem;
  }
