// Disable Transition

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

//cursor-pointer
.cursor-pointer {
  cursor: pointer;
}

// Margin
.margin-auto {
  margin: auto;
}

//Pointer Events
.pointer-events-none {
  pointer-events: none;
}

//capitalize text
.capitalize {
  text-transform: capitalize;
}
