@import './scss-variables.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus-visible {
  outline: none;
}

input {
  background: transparent;
}

.app-input-color {
  color: var(--ion-color-medium);
}

.input-group {
  width: 100%;
  padding-bottom: 1.6rem;
  position: relative;

  input {
    width: 100%;
    padding: 1.2rem .8rem;
    background: none;
    border: none;
    color: var(--ion-color-medium);
    font-size: 1.6rem;
    font-weight: 500;
    border: .2rem solid $button-gray;
    border-radius: 1.5rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--ion-color-medium);
    }
  }

  .app-error {
    padding-top: .4rem;
    color: var(--ion-color-danger);
    text-align: start;
  }
}

.app-input-container {
  position: relative;
  display: flex;
  .app-input {
    flex-grow: 1;
  }

  .app-input:focus + .append-icon {
    border-color: var(--ion-color-medium);
  }

  .prepend-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    &+.app-input {
      padding-left: 2rem;
      margin-left: 0;
    }
  }

  .append-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .3rem;
  }
}

.input-container {
  position: relative;
}

.generic-input {
  width: 100%;
  padding: 1.2rem .8rem;
  background: none;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
  border: .2rem solid rgba(var(--ion-color-primary-rgb), .1);
  border-radius: 1rem;
  color: var(--ion-color-dark);

  &.white-background {
    background-color: $white !important;
  }
}

/*---------From Library----------*/
:focus{outline: none;}

.input-effect {
  position: relative;
}

.effect-1 {
  border: 0;
  padding: .4rem 0;
  border-bottom: .1rem solid $dark-gray;
}

.effect-1 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: .2rem;
  background-color: var(--ion-color-primary);
  transition: 0.4s;
  overflow: hidden;
}
.effect-1:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}

.effect-9{border: 1px solid #ccc; padding: 7px 14px 9px; transition: 0.4s; overflow: hidden;}
.effect-9 ~ .focus-border:before,
.effect-9 ~ .focus-border:after{content: ""; position: absolute; top: 0; right: 0; width: 0; height: 2px; background-color: var(--ion-color-primary); transition: 0.2s; transition-delay: 0.2s;overflow: hidden;}
.effect-9 ~ .focus-border:after{top: auto; bottom: 0; right: auto; left: 0; transition-delay: 0.6s;}
.effect-9.danger ~ .focus-border:before,
.effect-9.danger ~ .focus-border:after,
.effect-9.danger ~ .focus-border i:before,
.effect-9.danger ~ .focus-border i:after {background-color: var(--ion-color-danger);}
.effect-9 ~ .focus-border i:before,
.effect-9 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: var(--ion-color-primary); transition: 0.2s;}
.effect-9 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0; transition-delay: 0.4s;}
.effect-9:focus ~ .focus-border:before,
.effect-9:focus ~ .focus-border:after{width: 100%; transition: 0.2s; transition-delay: 0.6s;}
.effect-9:focus ~ .focus-border:after{transition-delay: 0.2s;}
.effect-9:focus ~ .focus-border i:before,
.effect-9:focus ~ .focus-border i:after{height: 100%; transition: 0.2s;}
.effect-9:focus ~ .focus-border i:after{transition-delay: 0.4s;border-radius:1rem;}

.effect-14 {border: 0; padding: 7px 15px; border: 1px solid rgba(var(--ion-color-primary-rgb), .1); position: relative; background: transparent;border-radius: 1rem;overflow:hidden;}
.effect-14 ~ .focus-bg:before,
.effect-14 ~ .focus-bg:after{content: ""; position: absolute; left: 0; top: 0; width: 0; height: 0; background-color: rgba(var(--ion-color-primary-rgb), .1); transition: 0.3s; z-index: -1;}
.effect-14.danger ~ .focus-bg:before,
.effect-14.danger ~ .focus-bg:after{background-color: rgba(var(--ion-color-danger-rgb), .1);}
.effect-14 ~ .focus-bg:before {border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;}
.effect-14 ~ .focus-bg:after {border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;}
.effect-14:focus ~ .focus-bg:before{transition: 0.3s; width: 50%; height: 100%;}
.effect-14 ~ .focus-bg:after{left: auto; right: 0; top: auto; bottom: 0;}
.effect-14:focus ~ .focus-bg:after{transition: 0.3s; width: 50%; height: 100%;}
