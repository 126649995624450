//scroll bar css styles
::-webkit-scrollbar {
  width: .8rem;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 1.5rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: var(--scrollbar-color);

  &:hover {
    background: var(--ion-color-primary);
  }
}
